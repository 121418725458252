import Checkbox from '@components/Checkbox';
import IconArrowDown from '@icons/IconArrowDown';
import { categoryService } from '@services/categoryService';
import { sortByDate } from '@utils/common';
import constant from '@utils/constant';
import { getCategoriesSelector } from '@utils/selectors';
import classNames from 'classnames';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

const FilterCategory = memo(({ values, onChange }) => {
   const [optionList, setOptionList] = useState([]);
   const dataCanceler = useRef();

   const { data, loading } = useSelector(getCategoriesSelector);
   const { totalPages, currentPage, content } = data || {};

   const valueKeeperRef = useRef({
      currentPage: currentPage,
      funcFetchData: categoryService.loadCategories,
   });

   useEffect(() => {
      valueKeeperRef.current = {
         ...valueKeeperRef.current,
         currentPage: currentPage,
      };
   });

   useEffect(() => {
      return () => {
         if (dataCanceler.current) {
            dataCanceler.current.cancel();
         }
         if (valueKeeperRef.current.currentPage > 1) {
            valueKeeperRef.current.funcFetchData({
               page: 1,
               page_size: constant.defaultPagesize,
               order_by: 'updated_at',
               sort_type: 'DESC',
            });
         }
      };
   }, []);

   useEffect(() => {
      if (content) {
         const sortedContent = [...content].sort(sortByDate('updated_at'));
         setOptionList(sortedContent);
      }
   }, [content]);

   const hasNextPage = currentPage && totalPages > currentPage;
   
   const loadNextPage = useCallback(() => {
      if (loading) {
         return;
      }
      if (hasNextPage) {
         dataCanceler.current = valueKeeperRef.current.funcFetchData({
            page: currentPage + 1,
            page_size: constant.defaultPagesize,
            order_by: 'updated_at',
            sort_type: 'DESC',
         });
      }
   }, [hasNextPage, currentPage, loading]);

   const handleChange = useCallback(
      (option) => {
         onChange && onChange(option);
      },
      [onChange]
   );

   console.log('optionList', optionList);
   console.log('hasNextPage', hasNextPage);

   return (
      <div className={classNames('checkbox-group', 'filter-category')}>
         {!loading && optionList.length === 0 && <div className="empty">No categories</div>}
         {optionList.map((option) => {
            const isChecked = values.some((i) => i === option.uid);
            return (
               <div className="option-group-wrapper" key={option.uid}>
                  <Checkbox
                     label={option.name}
                     value={option.uid}
                     // colorActive={colorActive}
                     isChecked={isChecked}
                     onChange={() => handleChange(option)}
                  />
               </div>
            );
         })}
         {hasNextPage && (
            <div className="show-more" onClick={loadNextPage}>
               {loading ? (
                  'Loading...'
               ) : (
                  <>
                     Show more <IconArrowDown />
                  </>
               )}
            </div>
         )}
      </div>
   );
});

export default FilterCategory;
